{
  "name": "ecole-du-papillon-invisible",
  "version": "1.1.13",
  "description": "École du Papillon Invisible",
  "author": "Thomas Egain",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/tegain/ecole-du-papillon-invisible.git"
  },
  "license": "MIT",
  "private": true,
  "engines": {
    "node": "^20.9.0"
  },
  "scripts": {
    "nx": "nx",
    "postinstall": "husky install",
    "chore:format": "nx format:write --libs-and-apps",
    "chore:lint": "nx run-many -t lint",
    "back-office:start": "nx serve back-office",
    "back-office:build": "nx build back-office",
    "back-office:build:staging": "nx build back-office -c staging",
    "back-office:test": "nx test back-office",
    "back-office:test:ci": "nx test back-office --runInBand --coverage",
    "back-office:lint": "nx lint back-office --fix",
    "backend:build": "nx build backend",
    "backend:start": "nx build backend && nx serve backend",
    "backend:deploy:staging": "firebase deploy --config=firebase.json --only functions  --project ecolepapilloninvisible-dev",
    "backend:deploy:production": "firebase deploy --config=firebase.json --only functions --project ecolepapilloninvisible",
    "backend:lint": "nx lint backend --fix",
    "website:start": "nx serve website",
    "website:build": "nx build website"
  },
  "dependencies": {
    "@angular/animations": "~17.0.0",
    "@angular/cdk": "^17.0.0",
    "@angular/common": "~17.0.0",
    "@angular/compiler": "~17.0.0",
    "@angular/core": "~17.0.0",
    "@angular/fire": "~17.0.1",
    "@angular/forms": "~17.0.0",
    "@angular/platform-browser": "~17.0.0",
    "@angular/platform-browser-dynamic": "~17.0.0",
    "@angular/router": "~17.0.0",
    "@date-fns/tz": "^1.0.2",
    "@nuxtjs/i18n": "~8.3.1",
    "@nx/angular": "17.2.7",
    "@rx-angular/template": "~17.0.1",
    "@sentry/angular": "~8.19.0",
    "@sentry/cli": "~2.32.2",
    "@supercharge/promise-pool": "~3.2.0",
    "@vee-validate/nuxt": "~4.13.1",
    "@vee-validate/yup": "~4.13.1",
    "body-parser": "^1.20.2",
    "cors": "^2.8.5",
    "date-fns": "^4.1.0",
    "express": "^4.19.2",
    "firebase": "^9.14.0",
    "firebase-admin": "~12.0.0",
    "firebase-functions": "~4.6.0",
    "helmet": "~7.1.0",
    "inversify": "^6.0.2",
    "nanoid": "~5.0.4",
    "primeflex": "~3.3.1",
    "primeicons": "~7.0.0",
    "primeng": "~17.11.0",
    "primevue": "~3.53.0",
    "quill": "~1.3.7",
    "ramda": "~0.29.1",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.0",
    "sharp": "~0.33.4",
    "tslib": "^2.3.0",
    "vee-validate": "~4.13.1",
    "vue-recaptcha-v3": "^2.0.1",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.0.0",
    "@angular-devkit/core": "~17.0.0",
    "@angular-devkit/schematics": "~17.0.0",
    "@angular-eslint/eslint-plugin": "~17.0.0",
    "@angular-eslint/eslint-plugin-template": "~17.0.0",
    "@angular-eslint/template-parser": "~17.0.0",
    "@angular/cli": "~17.0.0",
    "@angular/compiler-cli": "~17.0.0",
    "@angular/language-service": "~17.0.0",
    "@commitlint/cli": "~18.4.3",
    "@commitlint/config-angular": "~18.4.3",
    "@nuxtjs/seo": "~2.0.0-rc.11",
    "@nuxtjs/sitemap": "~5.2.2",
    "@nx-toolkits/firebase": "~0.7.1",
    "@nx/devkit": "17.2.7",
    "@nx/esbuild": "17.2.7",
    "@nx/eslint": "17.2.7",
    "@nx/eslint-plugin": "17.2.7",
    "@nx/jest": "17.2.7",
    "@nx/js": "17.2.7",
    "@nx/playwright": "17.2.7",
    "@nx/workspace": "17.2.7",
    "@playwright/test": "~1.41.0",
    "@schematics/angular": "~17.0.0",
    "@swc-node/register": "~1.6.7",
    "@swc/core": "~1.3.85",
    "@trivago/prettier-plugin-sort-imports": "~4.3.0",
    "@types/jest": "~29.5.10",
    "@types/node": "18.16.9",
    "@types/quill": "^1.3.10",
    "@types/ramda": "~0.29.9",
    "@typescript-eslint/eslint-plugin": "~6.19.0",
    "@typescript-eslint/parser": "~6.19.0",
    "axios": "~1.7.2",
    "esbuild": "~0.19.5",
    "eslint": "~8.48.0",
    "eslint-config-prettier": "~9.1.0",
    "eslint-plugin-change-detection-strategy": "~0.1.4",
    "eslint-plugin-import": "~2.29.1",
    "eslint-plugin-playwright": "~0.15.3",
    "eslint-plugin-unused-imports": "~3.0.0",
    "firebase-tools": "~13.13.0",
    "husky": "~8.0.3",
    "jest": "~29.7.0",
    "jest-environment-jsdom": "~29.7.0",
    "jest-environment-node": "~29.7.0",
    "jest-marbles": "~3.0.6",
    "jest-preset-angular": "~13.1.4",
    "jsonc-eslint-parser": "~2.4.0",
    "lint-staged": "~15.2.0",
    "node-mailjet": "~6.0.5",
    "nuxt": "~3.12.2",
    "nuxt-og-image": "~3.0.0-rc.65",
    "nuxt-primevue": "~0.3.1",
    "nx": "17.2.7",
    "prettier": "~2.8.8",
    "ts-jest": "~29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.2.2",
    "vue": "~3.4.30",
    "vue-router": "~4.4.0"
  },
  "lint-staged": {
    "*.{js,json,css,scss,less,md,ts,html,component.html}": [
      "npm run chore:format",
      "git add"
    ]
  }
}
